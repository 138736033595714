
import { Component, Vue } from 'vue-property-decorator'
import UploadFile from '@/components/uploadFile/Index.vue'
import { Info } from '@/types/question'
import { FileInfo, Tag } from '@/types/common'
import { ElForm } from 'element-ui/types/form'

@Component({
  components: { UploadFile }
})
export default class ExpertAdd extends Vue {
  private info: Info = {
    questionTitle: '',
    questionContent: '',
    questionUserNickname: '',
    isShow: 1,
    tagList: [],
    resourceList: []
  }

  private rules = {
    questionTitle: [{ required: true, message: '请输入问题标题', trigger: ['blur', 'change'] }],
    questionContent: [{ required: true, message: '请输入问题内容', trigger: ['blur', 'change'] }],
    questionUserNickname: [{ required: true, message: '请输入提问人名称', trigger: ['blur', 'change'] }]
  }

  private tagList: Array<Tag> = []
  private tagName = ''
  private addShow = false
  private submitShow = false

  private get title () {
    return this.$route.params.id ? '编辑' : '新增'
  }

  created () {
    this.getTagList()
    this.$route.params.id && this.getDetail()
  }

  getDetail () {
    this.$axios.get(this.$apis.question.selectQuestionByQuestionId, {
      questionId: this.$route.params.id
    }).then(res => {
      this.info = res
    })
  }

  getTagList () {
    this.$axios.get(this.$apis.common.thinktankTag).then(res => {
      this.tagList = res.list
    })
  }

  querySearch (queryString: string, cb: Function) {
    const tagList = this.tagList
    const results = queryString ? tagList.filter(this.createFilter(queryString)) : tagList
    this.addShow = results.length === 0
    // 调用 callback 返回建议列表的数据
    cb(results)
  }

  createFilter (queryString: string) {
    return (tag: Tag) => {
      return (tag.tagName.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
    }
  }

  // 标签选中
  handleSelect (v: Tag) {
    (this.info.tagList as Array<Tag>).push(v)
  }

  // 标签删除
  removeTag (index: number) {
    this.info.tagList.splice(index, 1)
  }

  // 自定义标签添加
  addTag () {
    const index = this.info.tagList.findIndex((item: Tag) => {
      return item.tagName === this.tagName
    })
    if (index === -1) {
      (this.info.tagList as Array<Tag>).push({
        tagId: null,
        tagName: this.tagName
      })
      this.tagName = ''
      this.addShow = false
    } else {
      this.$message({
        message: '标签重复',
        type: 'warning'
      })
    }
  }

  imgRemove (i: number) {
    this.info.resourceList.splice(i, 1)
  }

  onSuccess (file: FileInfo) {
    this.info.resourceList.push(file)
  }

  onSubmit () {
    (this.$refs.info as ElForm).validate((valid) => {
      if (valid) {
        this.submitShow = true
        const url = this.$route.params.id
          ? this.$apis.question.updateQuestion
          : this.$apis.question.insertQuestion
        this.$axios.post(url, this.info).then(() => {
          this.$message({
            message: '保存成功',
            type: 'success'
          })
          this.$router.push({ name: 'questionList' })
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }
}
